<template>
  <v-layout row wrap>
    <v-flex xs12 sm12 md12 lg12 xl12 mb-3 v-if="employee != null">
      <h3>Welkom {{ getEmployeeName }}</h3>
    </v-flex>
    <v-flex xs12 sm12 md12 lg12 xl12 v-if="employee != null && employee.hasTimesheet">
      Je moet nog <span :class="[{'red' : timesheetTodo == 40},{'display-2' : timesheetTodo == 40}]">{{timesheetTodo}}</span> uren invoeren deze week!
      <v-btn flat class="primary" @click="$router.push('/timesheet')">Aan de slag</v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ['employee', 'roles'],
  name: 'Home',
  data() {
    return {
      timesheetTodo: 0.0
    }
  },
  watch: {
    employee() {
      if (this.employee && this.employee.hasTimesheet) {
        this.getTimesheetTodo()
      }
    }
  },
  computed: {
    getEmployeeName () {
      return ((this.employee.firstname || '') + ' ' + (this.employee.surnamePrefix || '') + ' ' +  (this.employee.surname || '')).replace('  ', ' ')
    }
  },
  methods: {
    getTimesheetTodo () {
      this.$http.get('api/timesheet/todo')
          .then(request => {
            this.timesheetTodo = 40.0 - request.data
          })
          .catch((err) => { console.error(err) }) /* eslint-disable-line no-console */
    }
  },
  mounted() {
      if (this.employee && this.employee.hasTimesheet) {
        this.getTimesheetTodo()
      }    
  }
}
</script>